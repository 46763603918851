.navbar-container {
  height: 100vh;
  width: 15%;
  display: flex;
  gap: 2em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: gainsboro;
  position: fixed;
}

