#project-wrapper {
  background-color: #212121;
  color: white;
  padding-top: 20px;
  padding-left: 20px;
  display: grid; 
  grid-template-columns: 1fr auto;
  grid-template-columns: 1fr 3.3fr 0.5fr;
  font-family: 'Courier New';
  height: calc(100vh - 175px);
}

.animate__animated.animate__backInLeft {
  --animate-duration: 1.5s;
}

.project-content-wrapper {
  margin-left: 20px;
  overflow-y: auto;
}


.project-content-wrapper img {
  height: 406px;
  width: 391px;
  margin-right: 15px;
  border-radius: 12px;
  margin-left: 20px;
  margin-bottom: 5px;
}


.dev-right-main {
  grid-area: maincontent;
}

.dev-link {
  grid-area: link;
}

.dev-image {
  grid-area: img;
}

.dev-link {
  font-size: 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
}


