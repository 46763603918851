.card-wrapper {
  border-radius: 12px;
}

.card-image-wrapper {
  margin: 10px 10px;
  position: relative;
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  border-radius: 12px;
  color: white;
  
}
.card-image-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  opacity: 1;
}
.myMaps{
  grid-area: mymaps;
}

.development{
  grid-area: development;
}

.iterative{
  grid-area: iterative;
}

.responsive {
  grid-area: responsive;
}

.card-info-wrapper {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: left;
  flex-direction: column;
  gap: 10px; 
}

.card-wrapper:hover {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.card-wrapper:hover img{
  opacity: 0.2;
  transform: scale(1.1);
  transition: .7s ease;
}



.card-wrapper:hover .card-info-wrapper {
  border-radius: 12px;
  opacity: 1;
}