.project-sidebar-wrapper {
  background-color: #212121;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
  padding: 0px 10px;
  height: calc(100vh - 175px);
  background-color: #212121;
  color: white;
  font-family: 'Courier New';
  border-right: 1px solid white;
}

.project-sidebar-title {
  font-size: 2em;
}

.project-sidebar-description {
  font-size: 1.1em;
}

.tools-used {
  font-size: 1.2em;
}

.tools-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}