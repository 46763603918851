.main-wrapper {
  font-family: "Courier"
}

.main-grid {
  display: grid; 
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: 1.9fr 0.4fr 3.8fr;
  margin: 0 auto;
  justify-items: center;



  grid-template-areas: 
    "aboutme mymaps responsive"
    "aboutme development helper"
    "aboutme development iterative";
}


.helper {
  grid-area: helper;
  position: relative;
  font-size: 2em;
  color: indianred;
  -webkit-text-stroke: 3px #212121;
  text-transform: uppercase;
}

.helper::before{
  content: attr(data-text);
  position:absolute;
  top: 25%;
  left:0;
  width: 0;
  color: indianred;
  -webkit-text-stroke: 1px #212121;
  border-right: 2px solid indianred;
  overflow: hidden;
  animation: animate 6s linear infinite;
}

@keyframes animate 
{
  0%, 10%, 100%{
    width: 0;
  }
  70%, 90%
  {
    width: 100%;
  }



}