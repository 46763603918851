#it-image {
  width: 35%;
  height: 100%;
}

.dr-treat-anchor {
  color: mediumpurple;
  text-decoration: none;
  background-color: #212121;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

#in-sketches-paid-profile {
  color: cornflowerblue;
  border-bottom: 1px solid cornflowerblue;
}

#paid-profile-img {
  width: 70%;
  height: 70%;
  border-radius: 5px;
}

.it-sketches-tab {
  padding: 5px 5px;
  width: 170px;
  border: none;
  font-family: 'Courier New';
  background-color: unset;
  color: white;
  cursor: pointer;
}

#free-profile-img {
  width: 90%;
  height: 90%;
  border-radius: 5px;
} 

#emergencies-img {
  width: 90%;
  height: 90%;
  border-radius: 5px;
}

#pet-profiles-img {
  width: 80%;
  height: 80%;
  border-radius: 5px;
}

#it-wireframe {
  width: 43%;
  height: 50%;
  border-radius: 5px;
}

#avatar {
  width: 100%;
  height: 100%;
}

#dr-treat-email{
  width: 90%;
  height: 90%;
}

#dr-treat-response {
  width: 90%;
  height: 90%;
}

