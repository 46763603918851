html {
  scroll-behavior: smooth;
}

.App {
  background-color: #212121;
  color: white;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

