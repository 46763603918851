
.about-me-wrapper {
  border: 1px solid white;
  grid-area: aboutme;
  background-color:  #212121;
  color: white;
  padding: 0px 20px;
  border: none;
}

.my-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.socials-icon-wrapper:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.intro {
  font-size: 1.5em;
}