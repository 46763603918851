#br {
  background-color: white !important;
  height: 10px;
 
}
.container {
  min-height: 100vh;
}

.content-container {
  margin-left: 17%;
  width: 83%;
}

.home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.avatar-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.intro-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.name-intro {
  font-size: 1.4em;
}

.button-links-container {
  display: flex;
  gap: 1em;
}

.about-me-container {
  border: 1px solid black;
  width: 80%;
  height: 50%;
  background-color: gainsboro;
}
/* 
.project-grid {
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
  margin: 0px 20px;
  grid-template-areas: 
    "devProject respRedesign"
    "iterativeDesign  hypertextApp"
}

.dev-project {
  grid-area: devProject;
}

.resp-resdesign {
  grid-area: respRedesign;
}

.iterative-design {
  grid-area: iterativeDesign;
}

.hypertext-app {
  grid-area: hypertextApp
}

.project {
  background-color: gainsboro;
  border-radius: 10px;
}
 */

